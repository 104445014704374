import './Portfolio.css';
import React, { useState, useEffect } from 'react';
import loading from './loading.gif';

    // Define your loading component
const Loading = () => (
    <div className="loading-container">
      <img src={loading} alt="Loading..." />
    </div>
  );
  
  const Portfolio = () => {
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      // Simulate a delay to showcase the loading GIF
      const timeout = setTimeout(() => {
        setIsLoading(false);
      }, 3000); // Adjust the time as needed
  
      return () => clearTimeout(timeout);
    }, []);
  
    if (isLoading) {
      return <Loading />;
    }
  

    return (
        <div id="about" className="portfolio">
                <h2>                      Metanin Collective is about active deal flow
</h2>

                <div className="makegood">



                    <div className="makegood-text">
    
                    <h3>
                    Through our collective efforts, we hope to enrich the cultural landscape, stimulate creativity, and foster connections that transcend boundaries.                      
                     </h3>
                    <a href="#services"
                   >See more</a>
                    </div>

                </div>
        </div>
    )
}

export default Portfolio;