import './Join.css'

const Join = () => {

    return (
        <div
        id="subscribe"
        className="join"
        >
            <div class="content">
                <h2>Join our subscribers</h2>
                <p> Stay in the know about art, music, web3, and more.</p>
                <a
                             target="_blank"
                             rel="noopener noreferrer"
                href="https://metanin.substack.com//">
                <button className="join-myBtn">Sign up</button>
                </a>
                </div>
        </div>
    )
}

export default Join;