import './Hero.css';

const Hero = () => {

    return (
        <div
        className="hero"
        >
            <div className="one">

                 </div>

            <div className="two">
            <div
            className="section1"
            >

    
            <div class="hero-content">
        
               
                <h1>What is the Metanin Collective?</h1>
                    <h2> 
                    The Metanin Collective is where underrepresented creators, cultures, and communities converge. We use art and technology to forge meaningful connections across the globe, celebrating the stories of our shared human experience.

                    </h2>
                <h3>
                Our name, Metanin, plays on two powerful concepts:


                </h3>
                <p>
                <li>Melanin: The pigment that gives color to our skin, a universal element that unites us all on a fundamental level.
                </li>
                <li>
                Meta: Symbolizing the transformative power of going beyond current limitations, aiming for broader perspectives.
                </li>
The Metanin Collective is a testament to the belief that art and technology can break down barriers, fostering understanding and a vibrant celebration of our diverse world.               
 </p>
                
                <a href="https://partiful.com/e/gZjY5JJj4NatyoaDRx61">
                
                <button id="myBtn"
                >
                    Learn More
                    </button>
                    
                    </a>
                </div>
        
            </div>

            <div
            className="section2"
            >
          <div class="hero-content2">
                <h2>Join our Discord! </h2>
                <p>
                By joining our Discord community, you'll have exclusive access to behind-the-scenes content, participate in discussions, and connect with fellow artists and event organizers leading up to the big day.                
                </p>
                <a href="https://discord.gg/fsUppktm2k">
                <button id="myBtn2"
                >
                    Join Discord
                    </button>
                    </a>
            </div>
            </div>
            </div>
    
        </div>
    )
}

export default Hero;