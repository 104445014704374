import React from 'react';
import './NavClassComponent.css';
import nav from './nav.png';
import Links from './Links';
import exit from './exit.png';

class NavClassComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayLinks: false
    };
  }

  handleClick = () => {
    this.setState(prevState => ({
      displayLinks: !prevState.displayLinks
    }));
  };

  render() {
    return (
      <>
        <div className="Nav">
          
          {this.state.displayLinks ? (
            <img
              id="nav-icon"
              src={exit}
              alt="Exit Icon"
              onClick={this.handleClick}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <img
              id="nav-icon"
              src={nav}
              alt="Navigation Icon"
              onClick={this.handleClick}
              style={{ cursor: 'pointer' }}
            />
          )}
        </div>

        {this.state.displayLinks && <Links />}
      </>
    );
  }
}

export default NavClassComponent;