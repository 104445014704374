import './Quote.css'

const Quote = () => {

    return (
        <div
        id="partners"
        className="quote"
        >
            <div class="quote-content">
                <h2>
                    Current Partners
                </h2>

              <li>
                <a
                href="https://npclabs.xyz"
                >
                NPC Labs
                </a>
                </li>

                <li>
                <a
                href="https://ebigdesignnyc.com"
                >
                    eBig Design NYC
                    </a>
                </li>

                <li>
                    The Law Office of Christopher Moye
                </li>

                <li>
                    <a
                    href="https://www.temperedmindsent.com/">
                    Tempered Minds Ent
                    </a>
                </li>

                <li>
                <a
                    href="https://iconnatn.com/">
                    ICONNATN
                    </a>
                </li>

                <li>
                <a
                    href="https://dressmono.com/">
                    Monovision
                    </a>
                </li>
                </div>
        </div>
    )
}

export default Quote;