import './About.css';

const About = () => {

    return (
        <div
        className="about"
        >
            <div class="about-content">
                <h2>Mission</h2>
                <h3>
                ​At Metanin Collective, we believe in the power of creative expression and the transformative impact it has on individuals and communities. We cultivate an inclusive and vibrant artistic community where talented individuals can thrive and showcase their work using innovative and collaborative solutions. Our mission is to provide a supportive and complementary platform for artists.                
                </h3>
                </div>
        </div>
    )
}

export default About;