import './Header.css';
import NavClassComponent from './NavClassComponent';
import logo from './metanin.png'

function Header() {
  return (
    <div className="Header">
      <header className="top">

       <a href="https://partiful.com/e/gZjY5JJj4NatyoaDRx61">
        <img src={logo} className="logo" alt="Metanin Collective logo" />
        </a>

        <div className="mobile">
        <NavClassComponent />
        </div>

      </header>
    </div>
  );
}

export default Header;
