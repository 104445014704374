import './USPs.css'

const USPs = () => {

    return (
        <div className="usps">
                
            <div className="usps-heading">
                <h2>A network of 150,000+ members</h2>
            </div>

            <div className="big-group">
                <div className="small-group-1">
                    <div className="usp1">
                        <h3>
                        With our total groups across social platforms such as Facebook, Discord, and Instagram, we have access to large communities united under our common goal to promote marginalized artists.

                        </h3>
                    </div>
                 
                  
                </div>
                
                   
                  
                
            </div>

        </div>
    )
}

export default USPs;