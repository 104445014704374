import './Request.css'

const RequestAFreeConsultation = () => {

    return (
        <div
        id="services"
        className="request"
        >
            <div class="request-content">
                <h2>Collaboration with NPC Labs</h2>
                <h3>
                NPC Labs is a one-stop platform for creators and brands to build, monetize, and scale consumer products connected to their digital assets and community. We help creators and brands take the next step on their journey to building a global franchise.
We’re creating a safe destination for artists, creators, and collectors from all backgrounds to monetize their intellectual property (IP) and scale their businesses, by providing all the necessary software and infrastructure. We leverage blockchain technology to protect, authenticate, & distribute IP using a decentralized network of white-label suppliers for consumer goods.

                </h3>
                <a
                href="http://npclabs.xyz">
                <button
                className="request-myBtn"
                >
                    Get Started
                    </button>
                </a>
            </div>
        </div>
    )
}

export default RequestAFreeConsultation;