import './Footer.css';
import logo from './metanin.png';
import ig from './instagram.png';

const Footer = () => {

    return (
        <div className="footer">
            <div>
                <a href="./">
                <img
                src= {logo}
                alt="eBig Design NYC Logo"
                />
                </a>
            </div>

            <div>
            <p  className="copyright">Copyright Metanin Collective. 2024, All Rights Reserved.</p>

            </div>

            <div class="footer-about">
                <a
                href="./#about"
                >
                        About
                </a>
                <a
                href="./#services"
            >
                        Services
                </a>
                <a
                href="./#subscribe"
            >
                        Subscribe
                </a>
                <a
                href="./#partners"
          >
                        Partners
                </a>

                <a
                href="https://instagram.com/metanincollective"
                >
                        <img
                        id="ig"
                        alt="Instagram logo"
                        src={ig}
                         />
                </a>



            </div>


        </div>
    )
}

export default Footer;
