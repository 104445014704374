import './Header.css';

function Links() {
    return (
<div className="links">

<a
  href="#about"
  // target="_blank"
  rel="noopener noreferrer"
>
About
</a>

<a
  href="#services"
  // target="_blank"
  rel="noopener noreferrer"
>
Services
</a>

<a
  href="#subscribe"
  // target="_blank"
  rel="noopener noreferrer"
>
Subscribe
</a>

<a
  href="#partners"
  // target="_blank"
  rel="noopener noreferrer"
>
Partners
</a>

</div>
    )
};

export default Links;
